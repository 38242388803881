<template>
  <div  :gutter="20">
    <!-- Button -->
    <div class="top-table-container">
      <el-button @click.prevent="formNuevo">Agregar Tarifa</el-button>
    </div>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-select v-model="region" placeholder="Seleccione Region" @change="obtenerProducto" >
          <el-option
            v-for="item in optionsRegiones"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="12">
        <el-select v-model="producto" placeholder="Seleccione Producto" @change="obtenerValores">
          <el-option
            v-for="item in optionsProductos"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <br>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-table
          empty-text="Sin Resultados"
          v-if="vertabla"
          :data="tableData"
          border
          style="width: 100%">
          <el-table-column
            prop="diametro"
            label="Diametro"
            style="width: 25%">
          </el-table-column>
          <el-table-column
            prop="valor"
            label="Valor"
            style="width: 25%">
          </el-table-column>
          <el-table-column fixed="right" label="Acciones" width="220" align="center">
            <template slot-scope="scope">
              <el-button size="mini" icon="el-icon-edit" @click.prevent="formEditar(scope.row)">Editar</el-button>
              <el-button size="mini" type="danger" icon="el-icon-delete" @click.prevent="eliminar(scope.row)">Eliminar</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    
    <!-- Modal => Nuevo - Editar -->
    <el-dialog :title="tituloModal" :visible.sync="modal" width="35%" top="10vh" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form :model="tarifa" :rules="rules" ref="tarifa" label-position="top">
        <el-form-item prop="region" label="Region">
          <el-select v-model="tarifa.region" placeholder="Seleccione Region">
            <el-option
              v-for="item in optionsRegiones"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="producto" label="Producto">
          <el-select v-model="tarifa.producto" placeholder="Seleccione Producto">
            <el-option
              v-for="item in optionsProductos"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="diametro" label="Diametro">
          <el-select v-model="tarifa.diametro" placeholder="Seleccione Diametro">
            <el-option
              v-for="item in optionsDiametros"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Valor" prop="valor">
          <el-input v-model.number="tarifa.valor" placeholder="Valor en pulgadas" maxlength="9" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.prevent="modal = false">{{ "Cancelar" }}</el-button>
        <el-button v-loading.fullscreen.lock="loading" type="primary" @click.prevent="guardar('tarifa')">{{ accion }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "Valores",
  data() {
    return {
      modal: false,
      operacion: "",
      accion: "",
      tituloModal: "",
      region: '',
      producto: '',
      tarifa: {
        id: '',
        region: '',
        producto: '',
        diametro: '',
        diametro_id: '',
        valor: "",
      },
      rules: {
        region: [{required: true, message: "Este campo es requerido.", trigger: "blur"}],
        producto: [{required: true, message: "Este campo es requerido.", trigger: "change"}],
        diametro: [{required: true, message: "Este campo es requerido.", trigger: "change"}],
        descripcion: [{ required: true, message: "Este campo es requerido.", trigger: "blur" }],
        valor: [
          { required: true, message: "Este campo es requerido.", trigger: "blur" },
          { type: "number", message: "El campo metros solo recibe numeros." },
        ],
      },
      optionsRegiones: [],
      optionsProductos: [],
      optionsDiametros: [],
      tableData: [],
      loading: false,
      vertabla: false
    }
  },
  created() {
    this.obternerRegiones()
    this.obtenerProducto()
    this.obtenerDiametro()
  },
  computed: {
  },
  methods: {
    obternerRegiones() {
      axios
        .get("regiones")
        .then(res => {
          this.optionsRegiones = res.data.map( element => {
            return {
              'label': element.nombre,
              'value': element.id,
            }
          }) 
        })
    },
    obtenerProducto() {
      this.vertabla = false
      this.producto = ""
      this.tableData = [],
      axios
        .get("productos")
        .then(res => {
          this.optionsProductos = res.data.map( element => {
            return {
              'label': element.nombre,
              'value': element.id,
            }
          }) 
        })
    },
    obtenerDiametro() {
      axios
        .get("diametros")
        .then(res => {
          this.optionsDiametros = res.data.map( element => {
            return {
              'label': element.descripcion,
              'value': element.id,
            }
          }) 
        })
    },
    obtenerValores() {
      this.vertabla = true
      axios
        .get(`valores?region=${this.region}&producto=${this.producto}`)
        .then(res => {
          this.tableData = res.data.map(element =>{
            return {
              id: element.id,
              diametroID: element.diametro_id, 
              diametro: element.descripcion,
              valor: element.valor
            }
          })
        })
        .catch(error => {
          console.log(error);
        })
    },
    crear() {
      const datos = {
        region_id: this.tarifa.region,
        producto_id: this.tarifa.producto,
        diametro_id: this.tarifa.diametro_id,
        valor: this.tarifa.valor,
      };
      axios
        .post("valores", datos)
        .then(res => {
          if (res) {
            this.loading = true;
            setTimeout(() => {
              this.loading = false;
              this.$notify({
                title: "Éxito",
                dangerouslyUseHTMLString: true,
                message: `La <strong>Tarifa</strong> ha sido <strong>creada</strong> con éxito!`,
                type: "success",
              });
              this.obtenerValores();
            }, 1500);
          }
        })
        .catch(err => {
          console.log(err);
          this.$notify.error({
            title: "Error",
            dangerouslyUseHTMLString: true,
            message: `No se ha podido crear la Tarifa, ah ocurrido un error inesperado`,
          });
        });
      this.tarifa.region = "";
      this.tarifa.producto = "";
      this.tarifa.diametro = "";
      this.tarifa.valor = "";
    },
    editar() {
      const id = this.tarifa.id
      const datos = {
        region_id: this.tarifa.region,
        producto_id: this.tarifa.producto,
        diametro_id: this.tarifa.diametro_id,
        valor: this.tarifa.valor,
      };
      axios
        .put(`valores/${id}`, datos)
        .then(res => {
          if (res) {
            this.loading = true;
            setTimeout(() => {
              this.loading = false;
              this.$notify({
                title: "Éxito",
                dangerouslyUseHTMLString: true,
                message: `La <strong>Tarifa</strong> ha sido <strong>actualizada</strong> con exito!`,
                type: "success",
              });
            }, 1500);
            this.obtenerValores();
          }
        })
        .catch(err => {
          console.log(err);
          this.$notify.error({
            title: "Error",
            dangerouslyUseHTMLString: true,
            message: `No se ha podido editar la Tarifa, ah ocurrido un error inesperado`,
          });
        });
    },
    eliminar(data) {
      const id = data.id;
      this.$confirm("¿Está seguro que desea eliminar este Tarifa?", "Eliminar Tarifa", {
        confirmButtonText: "Si",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(res => {
          if (res) {
            axios
              .delete(`valores/${id}`)
              .then(res => {
                if (res) {
                  this.loading = true;
                  setTimeout(() => {
                    this.loading = false;
                    this.$notify({
                      title: "Éxito",
                      dangerouslyUseHTMLString: true,
                      message: `La Tarifa ha sido <strong>eliminada</strong> con éxito!`,
                      type: "success",
                    });
                    this.obtenerValores();
                  }, 1500);
                }
              })
              .catch(err => {
                console.log(err);
                this.$notify.error({
                  title: "Error",
                  dangerouslyUseHTMLString: true,
                  message: `No se ha podido eliminar la tarifa, ah ocurrido un error inesperado`,
                });
              });
          }
        })
        .catch(err => console.log(err));
    },
    guardar(tarifa) {
      this.$refs[tarifa].validate(valid => {
        if (valid) {
          if (this.operacion == "crear") this.crear();
          if (this.operacion == "editar") this.editar();
          this.modal = false;
          this.$refs[tarifa].resetFields();
        } else {
          return false;
        }
      });
    },
    //Botones y Formularios
    formNuevo() {
      this.modal = true;
      this.operacion = "crear";
      this.accion = "Guardar";
      this.tituloModal = "Nueva Tarifa";
    },
    formEditar(data) {
      //capturamos los datos del registro seleccionado y los mostramos en el formulario
      this.modal = true;
      this.operacion = "editar";
      this.accion = "Actualizar";
      this.tituloModal = "Editar Tarifa";

      this.tarifa.id = data.id 
      this.tarifa.region = this.region
      this.tarifa.producto = this.producto
      this.tarifa.diametro = data.diametro
      this.tarifa.diametro_id = data.diametroID
      this.tarifa.valor = data.valor
    },
    
  },
  watch: {
    
  },
};
</script>
